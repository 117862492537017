import React, { useState, useEffect } from 'react';
import countofs from './count.jpg';
import './App.css';

function App() {
const [inputText, setInputText] = useState('');
const [accessGranted, setAccessGranted] = useState(false); 
const [accessDenied, setAccessDenied] = useState(false);  
const [deniedMessage, setDeniedMessage] = useState('');
const [attempts, setAttempts] = useState(0);
  const denialMessages = [
    "Access denied...",
    "Nope",
    "Try again",
    "Poate data viitoare...",
    "Azi nu...",
    "Mai incearca",
    "Stam aici pana maine? Mai incearca...",
    "Nu te simt :)"
  ];

 useEffect(() => {
    const savedAttempts = localStorage.getItem('attempts');
    if (savedAttempts) {
      setAttempts(Number(savedAttempts));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newAttempts = attempts + 1;
    setAttempts(newAttempts);

    localStorage.setItem('attempts', newAttempts);

    if (inputText === 'ACT' || inputText === 'Act' || inputText ==='act') {
      setAccessGranted(true);
      setAccessDenied(false);
    } else {
      setAccessGranted(false);
      setAccessDenied(true);
const randomMessage = denialMessages[Math.floor(Math.random() * denialMessages.length)];
      setDeniedMessage(randomMessage);
    }
try {
      await fetch('http://kryptos.vpnq.net:5000/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ answer: inputText }),
      });
    } catch (err) {
      console.error('Error sending the answer to the server:', err);
    }
   // window.location.reload();
  };

  return (
    <div className="container">
	<div className="message">
		<p>A venit timpul, vei incepe calatoria de unul singur iar la finalul ei te vei alege cu o noua experienta comuna.</p>
<p>Cum petrecerile sunt despre noi, despre grup, despre a ne distra impreuna poti alege sa colaborezi in rezolvarea acestui puzzle. </p>
<p>Totusi, cel care rezolva oricare dintre pasii care urmeaza de unul singur, pastreaza beneficiile doar pentru el.</p>
<p></p>
<p>Important este ca fiecare pas vine cu un avantaj, foloseste-l cu atentie.</p>
<p>Unde vei ajunge? Vom vedea, iti povestesc mai multe pe drum.</p>
<p>Nu e departe 34.391999,132.451918.</p>
<p>Numele parcului. Trimite-mi un mesaj cu aceasta cheie pentru urmatorul pas.</p> 
<p>Păstrăm legătura</p>
       </div>
      <h1>Ti-am lasat un cuvant mare, <small className="smallText"> cu un corp mic</small>, nu pe limba noastra, stii ce ai de facut... Pastram legatura.</h1>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter text"
        />
        <button type="submit">Submit</button>
      </form>
{accessGranted && (
        <div className="image-container">
          <img
            src={countofs}  
            alt="Access Granted"
            style={{ marginTop: '20px' }}
          />
	<p style={{color:'green'}}> Access granted </p>
	<p> Ai reusit din {attempts} de incercari </p>
	<p> Afla cine e, ne vedem la IL Calcio pe terasa maine, 17.09.2024 la ora [cand a aparut el prima data in Londra]:00. Sa nu intarzii.</p>
	<p> Pastram legatura</p>
        </div>
      )}
{accessDenied && <p style={{color:'red'}}>{deniedMessage}</p>}
    </div>
  );
}

export default App;
